.App {
    display: flex;
    /* font-family: 'Roboto' sans-serif; */
}

.controls {
    margin: 20px;
}

input {
    display: block;
}

.item {
    margin: 12px;
}

.captain-bubble {
    position: absolute;
    top: 40px;
    left: 50px;
    width: 393px;
    height: 73px;
    /* background-color: rgba(0, 0, 0, 0.1); */
    font-family: 'Polsyh';
    color: rgba(0, 0, 0, 0.98);
}

.tintin-bubble {
    position: absolute;
    top: 125px;
    left: 50px;
    width: 275px;
    height: 43px;
    /* background-color: rgba(0, 0, 0, 0.1); */
    font-family: 'Polsyh';
    color: rgba(0, 0, 0, 0.98);
}

@font-face {
    font-family: 'Polsyh';
    src: url('./fonts/Polsyh.ttf');
}